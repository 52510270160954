<template>
    <NuxtLink v-slot="{href, navigate}" to="/resources/search" custom>
        <VJoyButton
            :variant="breakpoints?.isMobile ? 'ghost' : 'secondary'"
            :class="{'rc-search-cta': true, [buttonClass ?? '']: !!buttonClass}"
            icon="search"
            :href="href"
            size="small"
            @click="navigate"
        >
            <span class="message">{{ message }}</span>
        </VJoyButton>
    </NuxtLink>
</template>

<script lang="ts" setup>
    import {inject} from 'vue';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import {VJoyButton} from '@maltjoy/core-vue';

    defineProps<{message: string; buttonClass?: string}>();
    const breakpoints = inject(MEDIA_BREAKPOINTS);
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .rc-search-cta {
        @media (max-width: $mobile-breakpoint) {
            padding-left: 0 !important;
            padding-right: 0 !important;
            .message {
                display: none;
            }
        }
    }
</style>
