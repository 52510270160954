<template>
    <div>
        <NavbarUnlogged :prismic-document="navbarDocument" :show-search="false" class="resources-navbar" />
        <ClientOnly>
            <Teleport to="#header-customized-zone-search">
                <SearchCTA :message="navbarDocument?.data.searchButtonLabel ?? ''" button-class="desktop-rc-search" />
            </Teleport>
            <Teleport to="#mobile-nav-search">
                <SearchCTA :message="navbarDocument?.data.searchButtonLabel ?? ''" />
            </Teleport>
        </ClientOnly>

        <div class="page-overlay" />
        <slot />
        <!-- Event when logged in, we want to display this footer -->
        <FooterUnlogged :always-visible="true" />
    </div>
</template>

<script setup lang="ts">
    import {inject} from 'vue';
    import {ClientOnly, NavbarUnlogged, FooterUnlogged} from '#components';
    import {RC_NAVBAR} from '~/app/keys';
    import SearchCTA from '~/components/SearchCTA.vue';

    const navbarDocument = inject(RC_NAVBAR);
</script>

<style lang="scss">
    @use '~/assets/styles/page.scss' as *;
    @include page-style;
    .resources-navbar {
        h2 {
            text-align: left;
        }
        .button-placeholder {
            display: none;
        }
        .desktop-rc-search {
            order: -1;
        }
        .megamenu .item-title * {
            font-family: var(--joy-font-family-title);
        }
        #mobile-nav-search {
            display: block !important;
        }
    }
</style>
